// If you want to override variables do it here
@import 'variables';

// Import styles
@import '~@coreui/coreui/scss/coreui';

// If you want to add something do it here
@import 'custom';
@import '~angular-calendar/css/angular-calendar.css';
@import '~ngx-toastr/toastr';
@import '~ngx-bootstrap/datepicker/bs-datepicker.css';
@import '~swiper/css';
@import '~swiper/css/pagination';
@import '~swiper/css/navigation';
@import '~@ng-select/ng-select/themes/default.theme.css';

@font-face {
 font-family: InputFont;
 src: url(../assets/fonts/Times/TIMES.ttf) format('opentype');
}

@font-face {
 font-family: FamilyFont;
 src: url(../assets/fonts/Arial/ARIAL.woff) format('opentype');
}

@font-face {
 font-family: FamilyFontBold;
 src: url(../assets/fonts/Arial/ARIALBD.woff) format('opentype');
}

@font-face {
 font-family: InternRegular;
 src: url(../assets/fonts/Inter/static/Inter-Regular.ttf) format('opentype');
}

@font-face {
 font-family: InternSemiBold;
 src: url(../assets/fonts/Inter/static/Inter-SemiBold.ttf) format('opentype');
}

//For Immigration Form- Affidavit Or Statutory
@font-face {
 font-family: MyriadProBold;
 src: url(../assets/fonts/myriad-pro-cufonfonts-webfont/MYRIADPRO-BOLD.woff) format('opentype');
}

@font-face {
 font-family: MyriadRegular;
 src: url(../assets/fonts/myriad-pro-cufonfonts-webfont/MYRIADPRO-REGULAR.woff) format('opentype');
}

@media screen and (max-width: 1100px) {
 .position {
  position: absolute;
  margin-top: 50px;
 }

 .form-position {
  position: absolute;
 }

 .button-font {
  font-size: 0.65rem;
 }
}

.receipt-regular-font {
 font-family: InternRegular;
}

.receipt-semibold-font {
 font-family: InternSemiBold;
}

.medium {
 /* Montserrat Medium = 500 */
 font-weight: 500;
 font-family: Montserrat, 'Open Sans', Helvetica, Arial, sans-serif;
}

.semibold {
 /* Montserrat Semi-bold = 600 */
 font-weight: 600;
 font-family: Montserrat;
}

.forms-font {
 font-family: FamilyFont;
}

.app-body {
 padding-top: constant(safe-area-inset-top) !important;
 padding-top: env(safe-area-inset-top) !important;
 font-weight: 500;
 font-family: Montserrat;
}

// Angular Calendar
.calendar-container mwl-calendar-month-view {
 .cal-month-view {
  background-color: #454545 !important;
  padding: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .cal-cell-row {
   &:hover {
    background-color: rgba(255, 255, 255, 0.1);
   }

   mwl-calendar-month-cell.cal-events .cal-month-view {
    position: absolute !important;
   }
  }

  .cal-day-cell.cal-today {
   background-color: #00b9c2 !important;
   color: #ffffff !important;
  }

  mwl-calendar-month-cell.cal-cell {
   border-right: 0;
  }

  .calendar-container mwl-calendar-month-view .cal-month-view .cal-cell-top {
   position: absolute !important;
  }

  .cal-days {
   border: none;
  }

  .cal-cell-row {
   border-bottom: 0;
   height: 18px !important;
  }

  .cal-day-number {
   font-size: 0.8em !important;
   color: #ffffff !important;
   opacity: 0.85;
  }

  .cal-cell-top {
   min-height: 25px !important;
   display: flex;

   & > span {
    width: 100%;
    display: block;
   }
  }

  .cal-events {
   display: none !important;
  }

  //to hide badge
  span.cal-day-badge.ng-star-inserted {
   display: none !important;
  }

  .cal-badges.ng-star-inserted {
   display: none !important;
  }

  .cal-day-cell {
   height: 24px !important;
   min-height: 24px !important;

   .cal-day-number {
    font-weight: 500;
    margin: 0 !important;
    float: none;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
   }

   &.cal-weekend {
    .cal-day-number {
     color: #ff4545;
    }
   }

   &.cal-out-month {
    .cal-day-number {
     opacity: 0.35;
    }
   }

   &.cal-today {
    background-color: #629560;

    .cal-day-number {
     opacity: 1;
    }
   }
  }

  .cal-header {
   .cal-cell {
    color: #fff;
    font-size: 10px;
    font-weight: 500;
   }
  }

  .cal-cell-row .cal-cell:hover,
  .cal-cell.cal-has-events.cal-open {
   background: rgba(255, 255, 255, 0.25);
  }
 }
}

.astericks {
 color: red;
 font-weight: 400;
}

@mixin common-height {
 height: 70vh;
 overflow-y: scroll;
}

.table-responsive-width {
 thead {
  th {
   min-width: 50px;
  }

  th.col-1.date {
   min-width: 110px;
  }

  th.col-1.docket-date {
   min-width: 135px;
  }

  th.col-1.counsel {
   min-width: 50px;
  }

  th.col.particular {
   min-width: 30rem;
  }

  th.col-1.time {
   min-width: 84px;
  }
 }
}

.sidebar .nav-link:hover {
 color: #fff;
 background: #68647a !important;
 border-top-left-radius: 10px !important;
 border-bottom-left-radius: 10px !important;
}

.sidebar .nav-link {
 display: block;
 padding: 0.5rem 1rem;
 color: #fff;
 text-decoration: none;
 background: transparent;
}

.calendar-loading {
 display: none !important;
}

.search-bar {
 display: flex;
 position: relative;
 border: 2px solid #b4b4b4;
 border-radius: 10px;
 background-color: #ffffff;

 &__input {
  width: 100%;
  padding: 2px 12px !important;
  border: none !important;

  box-shadow: none !important;
 }

 &__input.active {
  width: 100%;
  padding: 2px 12px !important;
  border: none;
 }

 &__button {
  position: absolute;
  top: 4px;
  right: 4px;
  border-radius: 4px;
 }

 .search-button {
  background-color: #fff;
 }
}

mwl-calendar-month-view.ng-star-inserted {
 border-bottom-left-radius: 10px !important;
 border-bottom-right-radius: 10px !important;
}

.sidebar .nav-link .nav-icon {
 color: #ffffff !important;
}

app-sidebar-nav-divider.nav-divider {
 height: 3px !important;
}

.btn.btn-outline-secondary {
 color: #000 !important;
}

.monthcontainer {
 border-radius: 15px;
}

app-sidebar-nav-link.active {
 background-color: #474554;
 color: #ffffff;
 border-bottom-left-radius: 10px;
 border-top-left-radius: 10px;
}

.sidebar .nav-link.active {
 background-color: #474554;
 color: #ffffff;
 border-bottom-left-radius: 10px;
 border-top-left-radius: 10px;
}

.input-width {
 width: 100%;
}

.flex {
 display: flex;
}

.circle {
 border: 1px solid;
 border-radius: 50%;
 height: 110px;
 width: 110px;
 margin: 0 auto;
}

.bg {
 background: white;
 padding: 2%;
 border-radius: 15px;
}

.input-container-top {
 border-top: 1px solid #000;
 border-left: 1px solid #000;
 border-right: 1px solid #000;
}

.first-col {
 border-right: 0.5px solid;
}

.align-items-center {
 align-items: center;
}

.error-message {
 padding-top: 2px;
 padding-left: 20px;
 color: red;
}

form {
 .form-container {
  padding-left: 33px !important;
  padding-right: 33px !important;
 }

 .custom-form-style {
  label {
   font-weight: bold !important;
  }
 }
}

.fc-license-message {
 display: none;
}

.container {
 max-width: 1500px !important;
}

.btn-add-task {
 width: 40%;
 float: right;
}

.cstm-margin {
 margin-left: 20px;
 margin-right: 20px;
 margin-top: 0;
}

.client-name {
 font-size: 15px;
 font-weight: bold;
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;

 .nav-link.disabled {
  color: #fff;
 }
}

.accounting-nav {
 width: 175px;
 margin-left: 11% !important;
 background: unset !important;

 app-sidebar-nav-link.nav-item {
  margin-left: unset !important;

  a.nav-link {
   padding-left: 22%;
   padding-top: 8px;
   padding-bottom: 8px;
  }
 }
}

.hide-menu {
 visibility: hidden;
 height: 0;
}

.show-menu {
 visibility: visible;
 height: auto;
 margin-left: 18% !important;
}

.hide-dropdown {
 .multiselect-dropdown {
  .dropdown-list {
   display: none !important;
  }
 }
}

.customClass {
 .panel-heading {
  padding: 5px;
 }

 .panel-title {
  padding: 5px;

  button {
   color: #000000;
   font-weight: bold;
  }
 }
}

.pagination {
 display: flex;
 justify-content: end;
 padding-right: 5%;

 .pagination-previous {
  background: #2199e8 !important;
 }

 .pagination-next {
  background: #2199e8 !important;
 }

 .ngx-pagination .disabled {
  background: white !important;
 }

 .ngx-pagination .disabled {
  color: black !important;
 }
}

.submitbtn,
.cancelbtn {
 width: 80px;
}

.btn {
 text-transform: uppercase !important;
}

.common-badge {
 width: 5rem !important;
 font-size: 14px !important;
 border-radius: 10px !important;
 height: 30px !important;
 text-align: center !important;
 display: flex !important;
 justify-content: center !important;
 align-items: center !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
 max-width: 200px !important;
}

.multiselect-dropdown .dropdown-btn {
 border-color: #e4e7ea !important;
}

.form-control:disabled,
.form-control[readonly] {
 background-color: #f9f9f9 !important;
}

ul.nav.nav-justified.nav-pills {
 font-size: 17px !important;
}

.family-data {
 ul.nav.nav-justified.nav-pills {
  font-size: 12px !important;
 }

 ul.nav.nav-justified.nav-pills {
  a.nav-link {
   padding: 2px !important;
   height: 3.2rem !important;
   font-size: 12px !important;
   display: flex;
   align-items: center;
   justify-content: center;
  }
 }
}

.nav-pills .nav-link.active {
 background-color: #337ab7;
}

.nav-pills .nav-link {
 border-top: 1px solid;
 border-right: 0.5px solid;
 border-left: 1px solid;
}

.questionnaire-tabs {
 .nav-pills .nav-link {
  background-color: #b4c1ca;
  color: #ffffff;
 }

 .nav-pills .nav-link.active {
  background-color: #f3f3f3;
  color: #20a8d7;
  padding: 6px;
  border-bottom: 1px solid #337ab7;
 }
}

.ngx-file-drop__content {
 align-items: center;
 color: #0782d0;
 display: flex;
 height: 100px;
 justify-content: center;
 padding: 18px;
 flex-direction: column;
}

.ngx-file-drop__drop-zone {
 padding: 20px 0px;
}

.ngx-file-drop__drop-zone,
.ngx-file-drop__content {
 height: auto !important;
}

.tabssection {
 .nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
 }

 .nav-tabs .nav-link {
  white-space: nowrap;
 }
}

.immigration {
 .tab-content .tab-pane {
  padding: 1rem 5px !important;
 }

 .lawyer-immigration {
  ul.nav.nav-justified.nav-pills {
   a.nav-link {
    line-height: 1.1;
    font-size: 12px;
    padding: 1px;
    height: 3rem;
    margin-right: none !important;
    width: 13.5vw;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   }
  }
 }
}

.tab-content .tab-pane {
 padding: 1rem 1rem !important;
}

tab-content.brief-tab {
 padding: 2rem !important;
}

.data-form-container {
 .tab-content {
  border-radius: 8px;
 }
}

.card-header-bg {
 background-color: #20a8d8;
 color: #ffffff;
 font-size: 17px;
}

.documents,
.precedents {
 .card-header-bg {
  background-color: #337ab7 !important;
 }
}

.task-description {
 accordion-group {
  .card-header {
   background-color: unset;
   padding: unset;
   margin: unset;
  }

  .panel.card.panel-default {
   background-color: unset;
   border: unset;
  }

  .panel-body.card-block.card-body {
   padding: unset;
  }

  .panel-heading.card-header.panel-enabled {
   border: unset;
  }

  .d-flex.align-items-center.pb-2.br-2.setheadingpadding {
   padding-top: 8px;
  }
 }
}

.questionnaire-form {
 accordion-group {
  .card {
   background-color: unset;
   margin: 15px 0;

   button {
    color: rgb(24, 16, 16);
    font-size: 16px;
   }
  }

  .card-header {
   background-color: white;
   border: unset;
   padding: 0.45rem 1.25rem;
  }

  .panel-body {
   padding: 1.25rem;
  }

  .card-body {
   padding: 0.6rem;
  }
 }
}

.active-header {
 box-shadow: 5px 6px 2px 1px #f4f4f6;

 .panel-heading.card-header.panel-enabled {
  background: #f4f4f6 !important;
  border-bottom: 1px solid #0000002b;
 }
}

.circle-picker {
 width: 230px !important;
}

.document-color-picker {
 .circle-picker {
  width: unset !important;
 }
}

.text-transform {
 text-transform: uppercase;
}

.calendar-container mwl-calendar-month-view .cal-month-view .cal-cell-top {
 position: relative;
}

.cal-month-view .cal-day-badge {
 display: none;
}

.dis-none {
 display: none;
}

.sidebar-minimized app-sidebar-nav-link.nav-item.child-side-bar {
 width: 43px !important;
 margin-left: 0% !important;
}
.show-oncollapse {
 display: none;
 padding: 12px;

 .nav-link {
  padding: 0 !important;
  padding-top: 5px !important;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  width: 32px !important;
  height: 32px;
  border-radius: 100%;
  border: 1px solid #fff !important;
 }
}

app-sidebar-nav-link.nav-item.show-oncollapse.ng-star-inserted {
 padding-left: 7px;
}

.dis-block {
 display: block;
}

.ngx-file-drop__drop-zone {
 border-radius: 0px !important;
}

.child-side-bar {
 margin-left: 12% !important;
}

.child-settings {
 .nav-dropdown-items {
  margin-bottom: 8px;

  a {
   margin-left: 25px;
   margin-top: 8px;
   border-radius: 10px;
   width: 162px;
   text-align: center;
  }

  .success {
   a {
    background-color: #198754 !important;
   }
  }

  .error {
   a {
    background-color: #f86c6b !important;
   }
  }

  .warning {
   a {
    background-color: #ffc107 !important;
   }
  }
 }
}

.hide {
 display: none;
}

.documents {
 .ngx-file-drop__drop-zone {
  border: 2px dotted gray !important;
  padding-bottom: unset;
 }
}

.mini-drag {
 .ngx-file-drop__drop-zone {
  height: 5% !important;
  width: 150px !important;
  border: unset !important;
  padding: 0;
 }
}

.cal-container {
 .cal-week-view .cal-event {
  color: white;
  min-height: 22px;
 }
}

/* Action button */
.btn-action {
 background: transparent;
 border: none;
}

.share-icon {
 width: 18px;
}

.btn-action:active {
 background-color: unset !important;
 border: unset !important;
}

.btn-action:focus {
 box-shadow: unset !important;
}

.btn-save {
 width: 200px;
 font-size: 16px;
}

.swiper {
 width: 100%;
 height: 100%;

 .swiper-slide {
  background: #fff;
  width: 100% !important;
 }

 .swiper-pagination-bullet {
  width: 3%;
  border-radius: unset;
  height: 14px;
 }

 .swiper-wrapper {
  padding-top: 2%;
  padding-bottom: 4%;
 }

 .swiper-button-next,
 .swiper-button-prev {
  bottom: 0;
  top: unset;
 }

 .swiper-horizontal > .swiper-pagination-bullets,
 .swiper-pagination-bullets.swiper-pagination-horizontal,
 .swiper-pagination-custom,
 .swiper-pagination-fraction {
  top: 18px;
  height: 1%;
 }
}

@media (max-width: 575.98px) {
 .sidebar-show .main::before,
 .aside-menu-show .main::before {
  background: unset !important;
  display: none !important;
 }

 .cstm-margin {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0;
 }

 .mobile-title {
  text-align: left !important;
  padding-left: 10%;
 }
}

@media (min-width: 992px) {
 .sidebar-minimized .sidebar .nav-item .nav-icon {
  width: 45px;
  font-size: 0.875rem;
  overflow: hidden;
  padding-top: 5px;
 }

 .sidebar-minimized .sidebar .nav-item:hover {
  width: 45px;
  overflow: hidden;
 }

 .sidebar-minimized .sidebar .nav-link:hover {
  width: 45px;
  overflow: hidden;
 }

 .sidebar-minimized .sidebar .nav-item.hover {
  border-bottom-left-radius: 10px !important;
  border-top-left-radius: 10px !important;
  overflow: hidden;
 }

 .my-view .responsivecontainer.ng-star-inserted {
  width: 20% !important;
 }
}

@media screen and (min-width: 750px) and (max-width: 999px) {
 .monthcontainer {
  margin-left: auto !important;
  margin-right: auto !important;
 }

 .ngx-file-drop__content {
  padding: 0.5rem;
 }

 .my-view .responsivecontainer.ng-star-inserted {
  width: 50% !important;
 }

 .mobile-title {
  text-align: left !important;
  padding-left: 10%;
 }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
 .my-view .calendarcontainer {
  .col-md-3 {
   flex: 0 0 33% !important;
   max-width: 33.3333333% !important;
  }

  .monthcontainer {
   max-width: 220px !important;
  }
 }
}

@media screen and (min-width: 992px) and (max-width: 1279px) {
 .monthcontainer {
  width: 220px !important;
 }

 .table-responsive-width {
  thead {
   th.col-1.date {
    min-width: 110px;
   }

   th.col-1.docket-date {
    min-width: 110px;
   }
  }
 }
}

@media screen and (min-width: 992px) and (max-width: 1668px) {
 .immigration {
  .lawyer-immigration {
   ul.nav.nav-justified.nav-pills {
    a.nav-link {
     width: 12vw;
    }
   }
  }
 }
}

@media (max-width: 991px) {
 .sidebar .nav {
  padding-top: 15px;
 }

 .family-data {
  ul.nav.nav-justified.nav-pills {
   font-size: 11px !important;
   display: flex;
   flex-wrap: nowrap;
   margin-top: 1rem;
   overflow-x: scroll;

   .nav-item {
    min-width: 100px;
   }
  }

  ul li {
   scroll-snap-align: start;
   /* or: center */
   scroll-snap-stop: always;
  }

  /* Hide on Firefox */
  ul {
   scrollbar-width: none;
   scroll-padding-left: 1rem;
   /* Same as gutter */
   scroll-snap-type: x mandatory;
  }

  /* Hide on WebKit browsers */
  ul::-webkit-scrollbar {
   display: none;
  }

  ul.nav.nav-justified.nav-pills {
   a.nav-link {
    padding: 2px !important;
    height: 4rem !important;
    font-size: 12px !important;
    display: inline-block;
    align-items: center;
    justify-content: center;
   }
  }
 }
}

@media screen and (min-width: 560px) and (max-width: 1040px) {
 ul.nav.nav-justified.nav-pills {
  font-size: 15px !important;
 }

 ul.nav.nav-justified.nav-pills {
  a.nav-link {
   height: 45px !important;
   display: flex !important;
   align-items: center !important;
   justify-content: center !important;
   padding: 0.5rem !important;
  }
 }

 .family-data {
  ul.nav.nav-justified.nav-pills {
   font-size: 11px !important;
  }

  ul.nav.nav-justified.nav-pills {
   a.nav-link {
    padding: 3px !important;
    height: 3rem !important;
    font-size: 11px !important;
   }
  }
 }

 h3 {
  font-size: 20px;
 }

 a.nav-link {
  display: block;
  padding: 0.5rem !important;
 }

 .tab-content .tab-pane {
  padding: 1rem 0.5rem;
 }
}

@media screen and (min-width: 300px) and (max-width: 349px) {
 .circle-picker {
  width: 130px !important;
 }

 .display-none {
  display: none;
 }
}

@media (max-width: 767px) {
 .show-oncollapse {
  display: none;
 }
}

@media only screen and (min-width: 767px) and (max-width: 1400px) {
 th {
  font-size: 14px;
 }

 td {
  font-size: 13px;
 }

 h3 {
  font-size: 22px;
 }

 .cal-week-view .cal-time {
  font-size: 12px;
 }

 .btn.btn-dockets {
  font-size: 15px;
 }

 .btn,
 h6 {
  font-size: 12px;
 }
}

@media screen and (min-width: 350px) and (max-width: 559px) {
 h3 {
  font-size: 20px;
 }

 .ngx-file-drop__content {
  padding: 0.5rem;
 }

 ul.nav.nav-justified.nav-pills {
  font-size: 14px !important;
 }

 ul.nav.nav-justified.nav-pills {
  a.nav-link {
   height: 55px !important;
   display: flex !important;
   align-items: center !important;
   justify-content: center !important;
  }
 }

 #navbarSupportedContent {
  ul.header-end {
   float: right;
   margin-right: 18%;
  }
 }

 @supports (-webkit-touch-callout: none) {
  .table-responsive .table {
   max-width: none;
  }
 }

 .table-responsive-width {
  thead {
   th {
    font-size: 14px !important;
    min-width: 170px !important;
   }
  }

  tbody {
   td {
    font-size: 13px !important;
   }
  }
 }

 .tab-content .tab-pane {
  padding: 1rem 0.5rem !important;
 }

 color-circle {
  .circle-picker {
   width: 175px !important;
  }
 }

 .display-none {
  display: none;
 }

 .swiper {
  .swiper-wrapper {
   padding-top: 7%;
   padding-bottom: 15%;
  }

  .swiper-button-next,
  .swiper-button-prev {
   bottom: 0;
  }

  .swiper-pagination-bullet {
   width: 2%;
   border-radius: unset;
   height: 8px;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
   top: 5px;
   height: 1%;
   text-align: center;
  }
 }
}

@media screen and (min-width: 850px) and (max-height: 768px) {
 .display-none {
  display: none;
 }
}

@media screen and (min-width: 350px) and (max-width: 559px) {
 .bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: #fff;
  box-shadow: 0 0 10px 0 #aaa;
  position: relative;
  z-index: 1;
  left: 0;
 }
}

// datepicker css
.bs-datepicker-head,
.bs-datepicker-head,
.bs-datepicker button:active,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*='select-']:after,
.bs-datepicker-body table td[class*='select-'] span:after,
.bs-datepicker-body table td.active-week span:hover {
 background-color: #fff !important;
}

.bs-datepicker-body table td span.selected {
 background-color: #5cb85c !important;
}

.bs-datepicker-head button {
 color: black !important;
}

.visibilityhide {
 visibility: hidden;
 height: 0;
}

.visibilityshow {
 visibility: visible;
 height: auto;
}

@mixin tabNavLink($size, $height) {
 ul.nav.nav-justified.nav-pills {
  a.nav-link {
   font-size: $size;
   height: $height;
   display: flex;
   align-items: center;
   justify-content: center;
  }
 }
}

.accounting {
 @include tabNavLink(14px, 3.5rem);
}

.fees-disbursement {
 @include tabNavLink(13px, 3rem);
}

.accounting-tab {
 @include tabNavLink(13px, 3rem);
}

.trust {
 @include tabNavLink(13px, 3rem);
}

.statements {
 @include tabNavLink(13px, 3rem);
}

.law-firm-container {
 .popover {
  max-width: 350px !important;
 }
}

.accounting-card-header-bg {
 background-color: #d9e2e3 !important;
 color: black !important;
 font-size: 13px;
}

.invoice-container,
.disbursement-container {
 .popover-content {
  display: flex;
  flex-direction: column;
 }

 .action-btn {
  margin-bottom: 5px;
 }
}

.zoom {
 overflow: hidden;
}

.accounting-detail-card-header-bg {
 background-color: #80b9ea3b;
}

.invoice-detail-wrapper {
 background-color: #eee;
}

.caret {
 font-size: 18px;
}

.afidavit-form {
 .card-body {
  padding: 12mm 12.7mm 14mm 12.7mm !important;
 }
}

.font-13 {
 font-size: 13.3333px;
}

.font-12 {
 font-size: 12px !important;
}

.font-10 {
 font-size: 10.6667px;
}

.font-15 {
 font-size: 15.3333px;
}

.font-14 {
 font-size: 14px !important;
}

.print-padding {
 padding: 2px;
}

.border-dotted {
 border-bottom: 1px dotted !important;
}

.border-top-dotted {
 border-top: 1px dotted !important;
}

.border-solid {
 border-bottom: 1px solid !important;
}

.receipt-img {
 width: 135px;
 height: 139px;
 transform: rotate(4deg);
 margin: 20% 0 0 16%;
}

.dis-pdf-container {
 width: 70%;
 transform: rotate(5deg);
 margin: 5% 0 0 12%;
 padding: 21% 0 68% 0;
}

.rm-icon {
 position: absolute;
 top: -11%;
 font-size: 25px;
 color: red;
 cursor: pointer;
 right: 22%;
}

.browse {
 cursor: pointer;
 text-decoration: underline;
}

.mini-drag {
 height: 5% !important;
}

.pdf-rm {
 float: right;
 font-size: 25px;
 color: red;
 cursor: pointer;
 padding: 15% 5% 0 0;
}

.fileName-rm-icon {
 position: absolute;
 left: 45%;
 top: 85.5%;
 font-size: 25px;
 color: red;
 cursor: pointer;
}

.date-format {
 color: gray;
 font-size: 11px;
}

@media screen and (min-width: 320px) and (max-width: 559px) {
 .disbursement-container,
 .fee-container,
 .matter-list,
 .invoice-container,
 .trust-ledger {
  .table-responsive-width thead th {
   font-size: 14px !important;
   vertical-align: middle;
   min-width: 200px !important;
  }

  .table-responsive-width tbody td {
   padding: 12px 2px;
   font-size: 14px !important;

   label {
    font-size: 13px !important;
   }

   input {
    background-color: transparent !important;
    font-size: 13px;
   }

   .form-control:disabled {
    background-color: transparent !important;
    font-size: 11px;
   }
  }
 }
}

.title-header-width {
 width: 90%;
}

.alexur-form-control-bg {
 .form-control {
  background-color: rgba(0, 0, 0, 0.1);
  height: auto;
 }

 .form-control:focus {
  box-shadow: unset;
  background-color: #fafce0 !important;
  height: auto;
 }
}

.tinyText {
 color: #000000 !important;
 font-size: 14px;
}

.adobeReaderNote {
 font-size: 11px !important;
 line-height: 1.3 !important;
 color: #808080 !important;
}

.logo-size {
 font-size: larger;
}

.cke_textarea_inline,
.cke_editable,
.cke_editable_inline,
.cke_contents_ltr,
.cke_show_borders {
 font-size: 11pt;
}

.f-10 {
 font-size: 80%;
}

.f-17 {
 font-size: 13pt;
}

.f-16 {
 font-size: 16px;
}

.f-15 {
 font-size: 115%;
}

.f-14 {
 font-size: 14px;
}

.f-footer {
 font-size: 70%;
}

.f-12 {
 font-size: 12px;
}

// New font sizes
.style-of-cause {
 font-size: 10.6667px;
}

.font-brackets {
 font-size: 12px;
}

.header-title {
 font-size: 13pt;
}

.headers {
 font-size: 15.3333px;
}

@media print {
 .f-15 {
  font-size: 115%;
 }

 .f-16 {
  font-size: 15pt;
 }

 .f-14 {
  font-size: 12.5pt;
 }

 .f-17 {
  font-size: 17pt;
 }

 .f-footer {
  font-size: 75%;
 }

 .f-12 {
  font-size: 14px;
 }

 .f-10 {
  font-size: 85%;
 }

 .print-padding {
  padding: 2px;
 }

 // New font sizes
 .style-of-cause {
  //8pt
  font-size: 13.63px;
 }

 .font-brackets {
  //9pt
  font-size: 15.33px;
 }

 .header-title {
  //11.04pt
  font-size: 18.84px;
 }

 .headers {
  //9.96pt
  font-size: 16.99px;
 }

 .afidavit-form {
  .card-body {
   padding: 12mm 10mm 0 10mm !important;
  }
 }
}

.sidebar-nav {
 .nav {
  overflow-x: hidden;
 }
}

.pointer {
 cursor: pointer;
}

.rep-signature {
 mix-blend-mode: multiply;
 max-height: 110px;
 position: absolute;
 transform: translate(-50%, -50%);
}

.form-header-margin {
 margin-top: 7% !important;
}

.toast-bottom-center {
 margin-bottom: 50px;
 line-height: 15px;
}

.print-form-cstm-circle {
 margin: auto;
 position: absolute;
 width: 80%;
 margin: auto;
 background-color: #fff;
 z-index: 1000;
 left: 0;
 right: 0;
 margin-left: auto;
 margin-right: auto;
 top: 102px;

 background-color: transparent;

 .card {
  background-color: transparent;
  border: none;

  .card-content {
   background-color: transparent;
  }

  .card-body {
   padding-bottom: 0 !important;
  }
 }
}

.page-header-cstm-print {
 position: fixed;
 top: 0mm;
 width: 100.2%;
 left: -3px;
 background-color: #fff;

 z-index: 100;
 margin: auto;
 text-align: center;

 .input-height {
  height: 20px !important;
 }
}

.page-footer-cstm-print {
 position: fixed;
 padding-left: 17px;
 bottom: 0;
 width: 100%;
 z-index: 99;
 background-color: #fff;
 height: 80px;
 margin-bottom: -15px;
}

.fontStyle {
 font-family: InputFont;
 color: #000;
 font-size: 20.47px;
}

@media print {
 .print-form-cstm-circle {
  display: block;
  top: 60px;
  width: 112%;
  left: -50px;

  .card-body {
   padding: 0 16mm !important;
  }
 }

 .page-header-cstm-print {
  margin-top: 12mm !important;
 }

 .overlap-header-cstm-print {
  position: absolute;
  top: 0;
  left: 0;
  height: 115px;
  width: 101%;
  z-index: 999;
  background-color: white;
 }

 .min-height {
  min-height: 24px;
 }

 .font-i {
  font-style: normal;
  line-height: 1.2;
 }

 .no-borders {
  border: none !important;
 }

 .no-italics {
  font-style: normal !important;
 }

 .input-font {
  line-height: 1.2;
  font-size: 20.47px;
  font-family: InputFont;
 }

 .border-jurad {
  border-bottom: 1px dotted #000;
  border-top: 1px dotted #000;
 }

 .ck.ck-editor__editable_inline {
  font-family: InputFont;
  font-size: 20.47px !important;

  p {
   span {
    font-size: 20.47px !important;
   }
  }

  ol,
  ul {
   li {
    span {
     font-size: 20.47px !important;
    }
   }
  }
 }
}

.cke_textarea_inline,
.cke_editable,
.cke_editable_inline,
.cke_contents_ltr,
.cke_show_borders {
 color: #000;
}

.border-jurad {
 border-bottom: 1px dotted #000;
 border-top: 1px dotted #000;
}

.document-signing {
 max-width: 700px !important;
 height: calc(100vh - 70px);
 display: flex;
 justify-content: center;
 align-items: center;
 padding-right: 15px;
 padding-left: 15px;
 margin-right: auto;
 margin-left: auto;

 .row {
  width: 90%;
 }

 .sign-label {
  margin-top: 0.5rem;
  font-size: x-large;
 }

 .doc-card {
  border-radius: 0.75rem;
 }

 .text-size {
  font-size: 20px;
 }

 .not-found {
  width: 75%;
 }

 .not-found-text {
  font-size: 20px;
  font-weight: 600;
 }

 .link {
  font-size: 19px;
 }
}

.signature-btn {
 color: #1b8eb7;
 font-size: 13px;
 font-weight: bold;
 text-decoration: none;
}

.signature-btn:hover {
 text-decoration: underline;
}

.signature-btn-cancel {
 text-decoration: none;
 font-size: 13px;
 color: #f64846;
 font-weight: bold;
}

.signature-btn-cancel:hover {
 text-decoration: underline;
}

.formOpacity {
 opacity: 0.7;
}

.hide-scroll {
 overflow: hidden;
}

.resize {
 resize: none;
}

.scroll::-webkit-scrollbar {
 display: none;
}

.template {
 display: -webkit-box;
 -webkit-line-clamp: 2;
 -webkit-box-orient: vertical;
 overflow: hidden;
}

.folder-modal-list {
 .card-body {
  height: auto !important;
 }

 .no-file-found {
  height: 50vh;
 }

 .folder {
  background-color: #ffffff;
  border-radius: 5px;
 }

 .folder:hover {
  border: 1px solid #20a8d8;
  color: #20a8d8;
 }

 .folder-col {
  background-color: #f0f3f5;
 }

 .file :hover {
  background-color: #f0f3f5;
 }

 .selected {
  border: 1px solid #20a8d8;
  color: #20a8d8;
 }

 .selected-folder {
  color: #20a8d8;
  font-size: 15px;
 }

 .folder-list {
  max-height: calc(12 * 40px);
  overflow-y: auto;
 }

 .file-list {
  max-height: calc(12 * 40px);
  overflow-y: auto;
 }

 .folder-icon {
  font-size: 18px;
 }

 @media screen and (min-width: 767px) {
  .folder-modal-width {
   max-width: 65%;
   min-width: 65%;
  }
 }

 @media screen and (max-width: 767px) {
  .folder-modal-width {
   min-width: 65%;
  }

  .selected-header {
   background-color: #337ab7;
   color: #fff;
  }

  .other-header {
   color: #337ab7;
  }
 }
}

.template-attachment-modal {
 .template-width {
  max-width: 700px;
 }

 .attach-file {
  border-radius: 7px;
  background-color: #e4e7ea;
  display: inline-block;
  height: 40px;
  white-space: nowrap;
 }

 .scroll-files {
  overflow-x: auto !important;
 }

 .remove-file:hover {
  background-color: azure;
 }
}

.hideArrows {
 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
 }
}

@media screen and (max-width: 768px) {
 .edit-mobile-width {
  width: 100%;
 }
}

.back-icon {
 width: 30px;
}

// Remove arrows from input type number
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
 -webkit-appearance: none;
 -moz-appearance: none;
 appearance: none;
 margin: 0;
}

// for save form modal
.custom-alert-modal {
 width: 35rem;
 display: flex;
 align-items: center;

 button.close {
  font-size: 28px;
  outline: unset;
 }
}

.page {
 .fixed-top {
  z-index: 1000;
 }
}

//header part all forms
.fixed-top {
 top: 0 !important;
 position: fixed !important;
 width: 100% !important;
 background-color: rgba(35, 40, 44, 0.8) !important;
 height: 50px;

 img {
  float: left;
 }

 .buttons {
  margin-top: 6px;
 }
}
/*File uploading progress bar*/
.overlayProgress {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
 display: flex;
 justify-content: center;
 align-items: center;
 z-index: 9999; /* Ensure overlay appears on top */

 /* Center the progress bar inside the overlay */
 .progress-container {
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 50%;

  .progress-bar {
   height: 20px;
   background-color: #20a8d8; /* Green color */
   border-radius: 5px;
  }

  .progress-percentage {
   margin-top: 10px;
   font-size: 18px;
   font-weight: bold;
  }
 }
}

.precedent-accordion {
 accordion-group {
  .panel.card.panel-default {
   margin-bottom: 0;
  }
 }
}
