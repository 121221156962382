// Here you can add other styles
.error {
	color: red;
}

$white: white;
$blue: blue;
$grey: grey;
$dark-grey: #888888;
$orange: orange;
$red: red;
$lightgrey: lightgrey;
$light-grey: #d5d5d5;
$neon-silver: #c8ced3;
$trout-shade: #495057;
$concrete-white-shade: #f2f2f2;
$swiss-coffee-shade: #d9d2d2;
$milan-yellow: #fffea6;
$white-shade: #ffffff;
$light-blue-shade: #20a8d8;
$blue-shade: #5c95c5;
$denim-blue-shade: #1077c1;
$denim-light-blue-shade: #167495;
$spindle-blue-shade: #b6d6ec;
$solitude-blue-shade: #e9f5ff;
$astral-blue: #337ab7;
$eastern-blue: #1985ac;
$matisse-blue: #187da0;
$curious-blue: #1ba0cc;
$dark-blue-shade: #106cbd;
$blue-ribbon-shade: #016bfd;
$tropical-blue: #c5e9f8;
$lochmara-blue-shade: #0782d0;
$azure-radiance-blue-shade: #007bff;
$lightcyan: lightcyan;
$athens-grey: #f0f3f5;
$alto-grey-shade: #dddddd;
$faint-grey-shade: #d9d9d9;
$lilac-white: #fcfcfe;
$mercury: #e1e1e1;
$carnation-red-shade: #f86c6b;
$light-silver: #cccccc;
$silver-shade: #bbbbbb;
$silver: #bdbdbd;
$chalice-shade-silver: #aaaaaa;
$emrald-green-shade: #4dbd74;
$black: #000000;
$whisper-white: #f3f1f7;
$polar-shade: #ecf6fa;
$dove-grey: #666666;
$dusty-grey: #999999;
$hint-yellow-shade: #fafce0;