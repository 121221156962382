// Variable overrides
$navBar: #474554;

$sidebar: #353535;
$sidebarCalendar: #454545;

$mainContentBg: #f9f9f9;
$taskButton: #00B9c2;
$taskHeader: #efeff0;

$activeStatus: #1de688;
$overdueStatus: #d34646;
$pendingStatus: #ffc107;

.navbar-header-bg  {
    background-color: $navBar;
}

.main-container-bg {
    background-color: $mainContentBg;
}

.sidebar-bg {
    background-color: $sidebar;
}

.sidebar-calendar-bg {
    background-color: $sidebarCalendar;
}